<template>
    
  <v-card>
    <v-card-title>
      User Management
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="dialog = true"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        New User
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text>
      <template>
        <v-card-title class="pl-0 pr-0 pt-0">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              offset-sm="6"
            >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="userHeaders"
          :items="userTable"
          :items-per-page="5"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:[`item.action`]="props">
            <template>
              <v-icon
              dense
              class="mr-2 blue--text" @click="formUser = props.item, dialog = true, mode = 'edit'"
              >
                  mdi-account-edit-outline
              </v-icon>

              <v-icon
              dense
              class="mr-2 red--text" @click="deleteUserData = props.item, deleteUserDialog = true"
              v-if="$store.getters.user.USER_ID != props.item.USER_ID"
              >
                  mdi-trash-can-outline
              </v-icon>
              <v-icon
              dense
              class="mr-2 grey--text"
              v-else
              >
                  mdi-trash-can-outline
              </v-icon>
            </template>
          </template>
        </v-data-table>
      </template>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      max-width="50%"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 secondary white--text justify-center" v-if="mode != 'edit'">
          Add New User
        </v-card-title>
        <v-card-title class="text-h5 secondary white--text justify-center" v-else>
          Update User
        </v-card-title>

        <v-card-text class="base pt-2">
          <v-row>
            <v-col
              cols="12"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Full Name"
                v-model="formUser.NAME"
              ></v-text-field>
            </v-col>            
            <v-col
              cols="12"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="NRIC/Passport No"
                v-model="formUser.IC_NO"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Password"
                v-model="formUser.PASSWORD"
                type="password"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Email"
                v-model="formUser.EMAIL"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Phone No"
                v-model="formUser.PHONE_NO"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              dense
            >
              <v-select
                :items="designation"
                label="Designation"
                required
                outlined
                hide-details="auto"
                v-model="formUser.DESIGNATION"
                dense
              ></v-select>
            </v-col>
          </v-row>

  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialog = false, mode = 'new'"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="submitNewUser()"
            outlined
            v-if="mode != 'edit'"
          >
            Save
          </v-btn>
          <v-btn
            color="primary"
            @click="submitEditUser()"
            outlined
            v-else
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template>
      <v-dialog
      v-model="deleteUserDialog"
      width="600"
      scrollable
      >
      <v-card>
          <v-card-title class="justify-center title-bar-modul">
              <h4>Delete User </h4>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="mt-2">
            <v-row>

              <v-col
                  class="d-flex mt-3"
                  cols="12"
                  sm="12"
              >
                  <v-text-field
                      v-model="deleteUserData.USER_ID"
                      outlined
                      label="User ID"
                      dense
                      hide-details="auto"
                      readonly
                  ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
              
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                outlined
                @click="deleteUserDialog = false; deleteUserData = ''"
            >
                Cancel
            </v-btn>
            <v-btn
                color="red"
                outlined
                @click="deleteUser();"
            >
                Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template>
      <div class="text-center">
        <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="success"
        >

        {{ snackbarMsg }}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
        </v-snackbar>
      </div>
    </template>

  </v-card>

</template>

<script>
// -- import something here --
import axios from 'axios';

export default {

  data: () => ({
    dialog: false,
    mode: 'new',
    e6: 1,
    e1: 1,
    valid: true,
    designation: ['Administrator', 'User', 'Developer'],
    cropType: ['Fruits', 'Herbs', 'Vegetables'],
    cropUnit: ['kg','tangkai','tongkol'],
    search: '',
    userHeaders: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'NAME',
      },
      { text: 'Email', value: 'EMAIL' },
      { text: 'IC NO', value: 'IC_NO' },
      { text: 'Designation', value: 'DESIGNATION' },
      { text: 'Action', value: 'action' },
    ],
    userTable: [],

    formUser: {
      USER_ID: "",
      IC_NO: "",
      NAME: "",
      EMAIL: "",
      PHONE_NO: "",
      DESIGNATION: "",
      PASSWORD: ""
    },

    snackbar: false,
    timeout: 3000,
    snackbarMsg: "",

    deleteUserData: "",
    deleteUserDialog: false,



  }),

  mounted() {
    this.getAllUsers();
  },

  methods: {
    getAllUsers(){
      axios.get('https://atania.app/api/user/all.php', {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
              //'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhdGFuaWEua2xlb3N0ZWNobm9sb2dpZXMuY29tIiwiaWF0IjoxNjcyNzcyNDg1LCJuYmYiOjE2NzI3NzI0ODUsImV4cCI6MTY3Mjc3NDI4NSwidXNlcklkIjoxLCJlbWFpbCI6InJhamFAc2VyYXNpLnRlY2giLCJkZXNpZ25hdGlvbiI6IkRldmVsb3BlciIsIm5hbWUiOiJSYWphIEtodXphaXJpZSJ9.m77KQxfLhblKMecn4ZFimAlUv_EIqOvSC39J8RhAboM' ,
          }
      })
      .then((response) => {
          this.userTable = response.data;

      })
      .catch(error => {
          console.log(error);
      })
    },

    submitNewUser(){

      const payload = {
        IC_NO: this.formUser.IC_NO,
        NAME: this.formUser.NAME,
        EMAIL: this.formUser.EMAIL,
        PHONE_NO: this.formUser.PHONE_NO,
        DESIGNATION: this.formUser.DESIGNATION,
        PASSWORD: this.formUser.PASSWORD
      };

      axios.post('https://atania.app/api/user/new.php', payload, {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
        console.log(response);   
        this.snackbarMsg = 'New user successfully added!';
        this.snackbar = true;
        this.getAllUsers();
        this.dialog = false;
          
      })
      .catch(error => {
        console.log(error.response);
      });
    },

    submitEditUser(){

      const payload = {
        USER_ID: this.formUser.USER_ID,
        IC_NO: this.formUser.IC_NO,
        NAME: this.formUser.NAME,
        EMAIL: this.formUser.EMAIL,
        PHONE_NO: this.formUser.PHONE_NO,
        DESIGNATION: this.formUser.DESIGNATION,
        PASSWORD: this.formUser.PASSWORD
      };
      //let a = JSON.stringify(payload);

      console.log(payload);

      axios.post('https://atania.app/api/user/edit.php', payload, {
        headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          },
          
      })
      .then((response) => {
        console.log(response);   
        this.snackbarMsg = 'User successfully updated!';
        this.snackbar = true;
        
        this.formUser = {
          USER_ID: "",
          IC_NO: "",
          NAME: "",
          EMAIL: "",
          PHONE_NO: "",
          DESIGNATION: "",
          PASSWORD: ""
        }
        
        this.mode = 'new';

        this.getAllUsers();
        this.dialog = false;
          
      })
      .catch(error => {
        console.log(error.response);
      });
    },

    deleteUser(){

      axios.get('https://atania.app/api/user/delete.php?id=' + this.deleteUserData.USER_ID, {
        headers: {
            // 'Authorization': 'Bearer ' + this.$store.getters.token,
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
        }
      })
      .then((response) => {
          if(response.data.msg == 'succesful') {
            this.snackbarMsg = 'Your data successfully deleted!';
            this.snackbar = true;
            this.getAllUsers();
            this.deleteUserDialog = false;
          }
      })
      .catch(error => {
          console.log(error);
      })
    }
  },

};
</script>

<style lang="scss">
@import '~scss/main';


</style>


